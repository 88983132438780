// @flow strict

import { Col, Input, message, Modal, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./GroupManageDialog.css";
import lang from "../../../../services/lang";
import { useEffect } from "react";
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
function GroupManageDialog(props) {
  const { langIndex } = useSelector((x) => x.app);
  const {
    initUserInfo,
    groupList,
    group_ids,
    setGroupIds,
    editUser,
    isShowGroupModal,
    setIsShowGroupModal,
  } = props;
  const [availGroupIds, setAvailGroupIds] = useState([]);

  useEffect(() => {
    console.log("group_ids", group_ids);
    if (group_ids != "" && group_ids != undefined){
      setAvailGroupIds(group_ids.split(","));
    }
  }, [group_ids]);

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    if (initUserInfo != null) {
      setUserInfo({ ...initUserInfo, password: "" });
    }
  }, []);

  useEffect(() => {
    console.log("Available Groups", availGroupIds);
  }, [availGroupIds]);

  const onClickOk = () => {
    let newUserInfo = { ...userInfo, group_ids: availGroupIds.join() };
    setGroupIds(availGroupIds.join());
    editUser(newUserInfo);
    setIsShowGroupModal(false);
  };

  const onClickCancel = () => {
    setIsShowGroupModal(false);
  };

  const groupListUI = groupList.map((group, index) => {
    return (
      <div
        key={`group-dialog-${index}`}
        className="group-dlg-machine-item"
        onClick={() => {
          if (availGroupIds.includes(group.id)) {
            if (availGroupIds.length > 1) {
              let curAvailGroupIds = availGroupIds;
              setAvailGroupIds([...curAvailGroupIds.filter(
                (x) => x != group.id
              )]);
            }
          } else {
            let curAvailGroupIds = availGroupIds;
            curAvailGroupIds.push(group.id);
            setAvailGroupIds([...curAvailGroupIds]);
          }
        }}
      >
        <Row gutter={16} align={"middle"}>
          <Col flex={"auto"}>{group.name}</Col>
          <Col flex={"30px"}>
            {(availGroupIds.includes(group.id) || availGroupIds.length == 0) && (
              <CheckOutlined style={{ color: "green" }} />
            )}
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <div>
      <Modal
        title={
          lang(langIndex, "plant_groupeditdialog")
        }
        visible={isShowGroupModal}
        onOk={onClickOk}
        onCancel={onClickCancel}
        destroyOnClose={true}
        className="group-setting-modal-style"
      >
        <div
          className="group-dlg-machine-item"
          style={{ fontWeight: "bold" }}
          onClick={() => {
            setAvailGroupIds([]);
          }}
        >
          <Row gutter={16} align={"middle"}>
            <Col flex={"auto"}>{"All Groups"}</Col>
            <Col flex={"30px"}>
              {(availGroupIds.length == 0) && (
                <CheckOutlined style={{ color: "green" }} />
              )}
            </Col>
          </Row>
        </div>
        {groupListUI}
      </Modal>
    </div>
  );
}

export default GroupManageDialog;
